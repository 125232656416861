import React, { useState, useCallback, useEffect } from 'react';
import { GoogleMap, Autocomplete, DirectionsRenderer } from '@react-google-maps/api';
import '../styles/TaxiFareCalculator.css';
import { Modal, Button } from 'react-bootstrap'; // Import Modal and Button
import RegistrationForm from './RegistrationForm'; // Import the RegistrationForm component
import { Accordion, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
const containerStyle = {
    width: '100vw',
    height: '100vh',
    position: 'absolute',
    top: 0,
    left: 0
};

const center = {
    lat: 52.3555, // Centered in the UK
    lng: -1.1743
};

const councils = {
    EDDC: {
        name: 'East Devon District Council',
        tariffs: {
            tariff1: { name: 'Tariff 1', firstMile: 5.96, subsequentMile: 2.73, description: 'TARIFF 1:- Weekday Daytime Rate (Applies on weekdays between 7 am and 7 pm)' },
            tariff2: { name: 'Tariff 2', firstMile: 6.93, subsequentMile: 3.3, description: 'TARIFF 2:- Evening/Night & Sunday Rate (Applies on weekdays between 7 pm and 7 am and on Sundays)' },
            tariff3: { name: 'Tariff 3', firstMile: 9.02, subsequentMile: 3.85, description: 'TARIFF 3:- Bank Holiday, Christmas and New Year Rate' }
        }
    },
    DDC: {
        name: 'Dorset District Council',
        tariffs: {
            tariff1: { name: 'Tariff 1', firstMile: 6.6, subsequentMile: 2.8, description: 'TARIFF 1:- Day Rate (7 am until 11 pm)' },
            tariff2: { name: 'Tariff 2', firstMile: 9.9, subsequentMile: 4.2, description: 'TARIFF 2:- Unsocial Hours' },
            tariff3: { name: 'Tariff 3', firstMile: 13.2, subsequentMile: 5.6, description: 'TARIFF 3:- Special Rate (Christmas & New Year)' }
        }
    }
};

const TaxiFareCalculator = () => {
    const [startLocation, setStartLocation] = useState('');
    const [endLocation, setEndLocation] = useState('');
    const [selectedCouncil, setSelectedCouncil] = useState('EDDC');
    const [directionsResponse, setDirectionsResponse] = useState(null);
    const [fare, setFare] = useState(null);
    const [distance, setDistance] = useState(null);
    const [duration, setDuration] = useState(null);
    const [isCalculated, setIsCalculated] = useState(false);
    const handleOpenWebsite = () => {
        window.open("https://www.taxi-local.com/site2/find-local-taxis", "_blank");
      };
    // Fetch the user's current location
    const fetchCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    const currentLocation = new window.google.maps.LatLng(latitude, longitude);
    
                    // Use Google Maps Geocoder to reverse geocode
                    const geocoder = new window.google.maps.Geocoder();
                    geocoder.geocode({ location: currentLocation }, (results, status) => {
                        if (status === window.google.maps.GeocoderStatus.OK) {
                            if (results[0]) {
                                let address = results[0].formatted_address;
    
                                // Remove plus code (e.g., "Q2RC+9P") from the address
                                address = address.replace(/^[A-Z0-9\+]+\s*/, ''); // Regex to match plus code
    
                                setStartLocation(address);  // Set the cleaned-up address
                            } else {
                                alert("No address found for your location.");
                            }
                        } else {
                            alert("Geocoder failed due to: " + status);
                        }
                    });
                },
                (error) => {
                    alert("Unable to retrieve your location. Please enable location services.");
                }
            );
        } else {
            alert("Geolocation is not supported by this browser.");
        }
    };
    
    

    const calculateFare = (distance, tariffs) => {
        const fareResults = {};
        Object.keys(tariffs).forEach(tariffKey => {
            const tariff = tariffs[tariffKey];
            const firstMileFare = tariff.firstMile;
            const subsequentMilesFare = distance > 1 ? (distance - 1) * tariff.subsequentMile : 0;
            fareResults[tariffKey] = firstMileFare + subsequentMilesFare;
        });
        return fareResults;
    };

    const handleCalculateRoute = useCallback(() => {
        if (startLocation && endLocation) {
            const directionsService = new window.google.maps.DirectionsService();
            directionsService.route(
                {
                    origin: startLocation,
                    destination: endLocation,
                    travelMode: window.google.maps.TravelMode.DRIVING,
                },
                (response, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        setDirectionsResponse(response);
                        const route = response.routes[0].legs[0];
                        const distanceInMiles = route.distance.value / 1609.34; // Convert meters to miles
                        const durationInMinutes = route.duration.value / 60; // Convert seconds to minutes

                        setDistance(distanceInMiles);
                        setDuration(durationInMinutes);

                        const councilTariffs = councils[selectedCouncil].tariffs;
                        setFare(calculateFare(distanceInMiles, councilTariffs));
                        setIsCalculated(true); // Set the state to true when calculation is done
                    } else {
                        alert("Directions request failed due to " + status);
                    }
                }
            );
        } else {
            alert("Please enter both start and end locations.");
        }
    }, [startLocation, endLocation, selectedCouncil]);

    const mapOptions = {
        disableDefaultUI: true,
        zoomControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
    };

    const openGoogleMaps = () => {
        const mapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(startLocation)}&destination=${encodeURIComponent(endLocation)}&travelmode=driving`;
        window.open(mapsUrl, '_blank');
    };

    const resetCalculator = () => {
        setStartLocation('');
        setEndLocation('');
        setSelectedCouncil('EDDC');
        setDirectionsResponse(null);
        setFare(null);
        setDistance(null);
        setDuration(null);
        setIsCalculated(false); // Reset the state to show the form again
    };

    // State for modal visibility
    const [showRegistrationModal, setShowRegistrationModal] = useState(false);
    const handleShowModal = () => setShowRegistrationModal(true);
    const handleCloseModal = () => setShowRegistrationModal(false);

    return (
        <div className="map-container" style={{ alignItems: 'center', backgroundColor: '#F5F5F5' }}>
                <div className="row justify-content-center">
                    <div className="calculation col-md- col-lg-6 p-4">

                        {!isCalculated && (
                            <>
                                <h4 className="text-center" style={{ backgroundColor: 'yellow' }}>
                                    <img 
                                        src="/logo_01.jpg" 
                                        alt="Taxi Local Logo" 
                                        style={{ width: '100%', height: 'auto' }}  />
                                </h4>
                                <h2 className="text-center mb-4">Taxi Fare Calculator-v2.1</h2>
                                    <form style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                    <div className="mb-3">
    <label htmlFor="start">Start Location:</label>
    <div className="d-flex"> {/* Make this a flex container to align all elements in a row */}
        <Autocomplete 
            onPlaceChanged={() => setStartLocation(document.getElementById('start').value)}
            options={{
                componentRestrictions: { country: 'uk' }, // Restrict to the UK
            }} 
            className="flex-grow-1" // Ensure Autocomplete takes up available space
        >
            <input
                type="text"
                id="start"
                className="form-control"
                placeholder="Enter start location"
                value={startLocation}
                onChange={(e) => setStartLocation(e.target.value)}
                required
            />
        </Autocomplete>

        {/* Toggle between Clear and Use Current Location buttons */}
{startLocation ? (
    // Show Clear button when startLocation has a value (user input)
    <button 
        type="button" 
        className="btn btn-outline-secondary ms-2" // Add left margin for spacing
        onClick={() => setStartLocation('')}
    >
        Clear
    </button>
) : (
    // Show Use Current Location button when startLocation is empty
    <button 
        type="button" 
        className="btn btn-outline-primary ms-2" // Add left margin for spacing
        onClick={fetchCurrentLocation}
    >
     Current Location
    </button>
)}

    </div>
</div>


<div className="mb-3">
    <label htmlFor="end">End Location:</label>
    <div className="d-flex"> {/* Use d-flex to place elements in a row */}
        <Autocomplete 
            onPlaceChanged={() => setEndLocation(document.getElementById('end').value)}
            options={{
                componentRestrictions: { country: 'uk' }, // Restrict to the UK
            }}
            className="flex-grow-1" // Ensure Autocomplete takes up available space
        >
            <input
                type="text"
                id="end"
                className="form-control"
                placeholder="Enter end location"
                value={endLocation}
                onChange={(e) => setEndLocation(e.target.value)}
                required
            />
        </Autocomplete>

        {/* Conditionally render the Clear button when there is user input */}
        {endLocation && (
            <button 
                type="button" 
                className="btn btn-outline-secondary ms-2" // Add left margin for spacing
                onClick={() => setEndLocation('')}
            >
                Clear
            </button>
        )}
    </div>
</div>

                                    <div className="mb-3" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ width: '100%' }}>
                                            <label>Select District Council:</label>
                                            <select
                                                className="form-select"
                                                onChange={(e) => setSelectedCouncil(e.target.value)}
                                                value={selectedCouncil}
                                            >
                                                {Object.keys(councils).map(councilKey => (
                                                    <option key={councilKey} value={councilKey}>
                                                        {councils[councilKey].name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <button type="button" className="btn btn-success" style={{ width: '100%' }} onClick={handleCalculateRoute}>Calculate</button>
                                </form>
                                <div className="text-center mt-4">
                                    <Button variant="warning" onClick={handleShowModal}>
                                        Link to your Websites
                                    </Button>
                                </div>
                                {/* Modal for Registration Form */}
                                <Modal show={showRegistrationModal} onHide={handleCloseModal}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>For Taxi Operators</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <RegistrationForm />
                                    </Modal.Body>
                                </Modal>
                            </>
                        )}

                        {isCalculated && fare !== null && (
                            <div className="fare-calculation">
                                <div className="fare-results mt-10 pt-10">
                                <h4 className="text-center" style={{ backgroundColor: 'yellow' }}>
                                    <img 
                                        src="/logo_01.jpg" 
                                        alt="Taxi Local Logo" 
                                        style={{ width: '100%', height: 'auto' }} 
                                         />
                                </h4>
                                    <h3 className="text-center">Fare Calculation Estimate</h3>
                                    <h6 className="text-center mb-4">{startLocation} <br /> <strong>---- Going To ---- </strong> <br /> {endLocation}</h6>
                                    <p className="text-center"><strong>Distance: {Math.round(distance.toFixed(1))} miles</strong></p>
                                    <p className="text-center"><strong>Duration: {duration < 60 
                                                  ? `${Math.round(duration)} minutes` 
                                                  : `${Math.floor(duration / 60)} hours ${Math.round(duration % 60)} minutes`}
                                                    </strong>
                                    </p>

                                    
                                    {Object.keys(fare).map(tariffKey => (
                                        <div className="text-center" key={tariffKey}>
                                            <p><strong> {councils[selectedCouncil].tariffs[tariffKey].name}: £{Math.round(fare[tariffKey].toFixed(2))}</strong></p>
                                        </div>
                                    ))}
                                    <div className="text-center mt-4">
                                        <div className="text-center mb-2">
                                            <button className="btn btn-secondary mx-8" onClick={resetCalculator}>Reset</button>
                                        </div>
                                        <div className="text-center mb-2">
                                            <button className="btn btn-success mx-8" onClick={openGoogleMaps}>Navigate</button>
                                        </div>
                                        <div className="text-center mb-2">
                                        <button className="btn btn-primary mx-8" onClick={handleOpenWebsite}>Availability</button>
                                    </div>
                                    </div>
                                    <div className="accordion accordion-flush" id="accordionFlushExample">
                                        <h5 className='text-center mb-2 pt-5'>Tariffs Definition</h5>
    {Object.keys(fare).map((tariffKey, index) => (
        <div className="accordion-item" key={tariffKey}>
            <h2 className="accordion-header" id={`flush-heading${index}`}>
                <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#flush-collapse${index}`}
                    aria-expanded="false"
                    aria-controls={`flush-collapse${index}`}
                >
                    {tariffKey}
                </button>
            </h2>
            <div
                id={`flush-collapse${index}`}
                className="accordion-collapse collapse"
                aria-labelledby={`flush-heading${index}`}
                data-bs-parent="#accordionFlushExample"
            >
                <div className="accordion-body">
                    {councils[selectedCouncil].tariffs[tariffKey].description}
                </div>
            </div>
        </div>
    ))}
</div>


                                    <div className="disclaimer">
                                        <details className="small text-muted mt-4"><summary><strong>DISCLAIMER:</strong></summary>
                                        <p> The fares calculated by this tool are estimates based on the shortest driving distance and standard tariffs provided by the selected council. 
                                            Actual fares may vary due to factors such as traffic conditions, road closures, detours, or delays. 
                                            Additional charges may apply for waiting time, tolls, or other surcharges.</p></details>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
    );
};

export default TaxiFareCalculator;